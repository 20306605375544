import React, { createContext, useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

export const BlogContext = createContext({})

export const BlogProvider = BlogContext.Provider
export const BlogConsumer = BlogContext.Consumer

export default function Blogik({ settings: options, children }) {
  const settings = {
    ...{
      id: 'blog',
      postIds: [],
      limit: 6,
      usePagination: false,
    },
    ...options,
  }

  const {
    allWordpressPost: { edges },
  } = useStaticQuery(graphql`
    {
      allWordpressPost(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...postsFragment
          }
        }
      }
    }
  `)

  const [selectedFilters, setSelectedFilters] = useState([])
  const [page, setPage] = useState(1)

  const setSelectedFiltersLocal = (filter) => {
    const newSelectedFilters = [...selectedFilters]

    const filterIndex = newSelectedFilters.indexOf(filter)

    if (filterIndex === -1) {
      newSelectedFilters.push(filter)
    } else {
      newSelectedFilters.splice(filterIndex, 1)
    }

    setSelectedFilters(newSelectedFilters)
  }

  const incrementPage = () => {
    let newPage = parseFloat(page)

    newPage += 1

    setPage(newPage)
  }

  const decrementPage = () => {
    let newPage = parseFloat(page)

    newPage -= 1

    setPage(newPage)
  }

  const isSelected = (filter) => selectedFilters.indexOf(filter) !== -1

  const offset = page * settings.limit
  const posts = [...edges].splice(offset - settings.limit, settings.limit)

  return (
    <BlogProvider
      value={{
        setSelectedFilters: setSelectedFiltersLocal,
        selectedFilters,
        isSelected,
        page,
        setPage,
        incrementPage,
        decrementPage,
        offset,
        limit: settings.limit,
        filterPosts: () => null,
        unfilteredPosts: edges,
        posts,
        hasPosts: posts.length > 0,
        showMoreButton:
          edges.length > posts.length && offset * settings.limit < edges.length,
        showLessButton: offset > 1,
      }}
    >
      {children}
    </BlogProvider>
  )
}

const Button = styled.div`
  border: 1px solid ${({ theme }) => theme.color.main};
  outline: none;

  &:focus {
    outline: none;
  }
`

export const BlogFilter = ({ className, children, id }) => {
  return (
    <BlogConsumer>
      {(context) => {
        return (
          <Button
            className={className}
            role="button"
            data-active={context.isSelected(id) ? 1 : 0}
            onClick={() => {
              context.setSelectedFilters(id)
            }}
            onKeyPress={() => {
              return null
            }}
            tabIndex={0}
          >
            {children}
          </Button>
        )
      }}
    </BlogConsumer>
  )
}

export const BlogButton = ({ className, children, increment = true }) => {
  return (
    <BlogConsumer>
      {(context) => {
        return (
          <Button
            className={className}
            role="button"
            onClick={() => {
              if (increment) {
                context.incrementOffset()
              } else {
                context.decrementOffset()
              }
            }}
            onKeyPress={() => {
              return null
            }}
            tabIndex={0}
          >
            {children}
          </Button>
        )
      }}
    </BlogConsumer>
  )
}

const PaginationButton = styled.button`
  outline: none;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  line-height: 1;
  border: 1px solid ${({ theme }) => theme.color.face.black};

  &[data-active='true'] {
    border-color: ${({ theme }) => theme.color.face.main};
    background: ${({ theme }) => theme.color.face.main};
    color: #fff;
  }
`

export const BlogPagination = ({ className }) => {
  return (
    <BlogConsumer>
      {(context) => {
        const paginations = context.unfilteredPosts.length / context.limit
        const pages = []

        for (let i = 0; i < paginations; i += 1) {
          pages.push(i + 1)
        }

        if (pages.length === 1) return

        return (
          <div className="d-flex justify-content-center">
            {pages.map((page) => (
              <PaginationButton
                key={page}
                role="button"
                data-active={parseFloat(page) === context.page}
                onClick={() => {
                  context.setPage(page)
                }}
                onKeyPress={() => {
                  return null
                }}
                tabIndex={0}
              >
                {page}
              </PaginationButton>
            ))}
          </div>
        )
      }}
    </BlogConsumer>
  )
}
