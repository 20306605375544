import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import ParseContent from 'components/shared/ParseContent'

// Blog
import Blog from 'components/related/Blog'
import Blogik, {
  BlogConsumer,
  BlogButton,
  BlogPagination,
} from 'components/shared/Blogik'

const PageTemplate = ({
  data: {
    page: { path, title, yoast_meta: yoast },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={yoast} path={path} />

      <section className="color-background-main">
        <div className="container text-white py-5">
          <h1 className="text-center font-size-60">{title}</h1>
        </div>
      </section>

      <div className="container py-5">
        <Blogik
          settings={{
            limit: 9,
          }}
        >
          <BlogConsumer>
            {({ hasPosts, posts, showMoreButton, showLessButton }) => {
              return (
                <div>
                  {hasPosts && <Blog posts={posts} />}

                  {!hasPosts && <ParseContent content="Geen resultaten" />}

                  <div className="mt-5">
                    <BlogPagination />
                  </div>
                </div>
              )
            }}
          </BlogConsumer>
        </Blogik>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
