import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { motion } from 'framer-motion'

// Shared
import Image from 'components/shared/Image'
import ParseContent from 'components/shared/ParseContent'

const PostThumbnail = styled(Image)`
  min-height: 270px;
  max-height: 350px;
  object-fit: cover;
  
`

const PostTitle = styled.span`
  font-size: ${({ theme }) => theme.font.size[20]};
  font-weight: ${({ theme }) => theme.font.weight.xl};
`

const Blog = ({ posts }) => {
  return (
    posts.map(({ node }) => (
      <>
        {console.log(node)}
        <Post fields={node} key={node.wordpress_id} />
      </>
    ))
  )
}

const Post = ({ fields, key }) => (
  <div className="row mb-4 mb-lg-5" key={key}>
    <div className="col-lg-4">
      <motion.div whileTap={{ scale: 0.9 }} whileHover={{ scale: 1.1 }}>
        <PostThumbnail src={fields.acf.overview.image} className="mb-2 w-100" />
      </motion.div>
    </div>
    <div className="col-lg-8 px-3">
      {fields.acf.content.map((item, index) => (
        <div key={index}>
          <PostTitle>{item.title}</PostTitle>
          <ParseContent content={item.description} />
        </div>
      ))}
    </div>
  </div>
)

export default Blog
